import React from 'react'

const Indexpage = () =>  {
    return (
        <h1>
            Welcome to VinceDS.be
        </h1>
    )
}

export default Indexpage
